import React from "react";
import imgBanner from "../../assets/image/inner-page/png/trips-banner.png";

const Content9 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h3 className="font-size-10 mb-0 letter-spacing-n83">
              Controla tus viajes y gastos
            </h3>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              La gestión de viajes que te ahorrará tiempo y te hará feliz.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Banner */}
        <div className="row">
          <div className="col-12">
            <div
              className="pt-10 pt-lg-17 rounded-10"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <img className="w-100" src={imgBanner} alt="" />
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-puzzle-piece" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tipos de actividad</h4>
                <p className="font-size-5 mb-0">
                  Configura por cada tipo de actividad la cadena de aprobación
                  del viaje.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-red shadow-red rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-user-check" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Aprobación</h4>
                <p className="font-size-5 mb-0">
                  Si lo necesitas puedes decidir quienes deben aprobar los
                  viajes en cada fase: solicitud, planificación y liquidación.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-plane" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Agencia de viajes</h4>
                <p className="font-size-5 mb-0">
                  Si trabajas con una agencia de viajes se le notifica por email
                  de las necesidades.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1100}
              data-aos-once="true"
            >
              <div className="square-60 bg-dark-green shadow-dark-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-envelope" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Comunicación</h4>
                <p className="font-size-5 mb-0">
                  Visualiza la respuesta de la agencia sin salir de Timeview.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1300}
              data-aos-once="true"
            >
              <div className="square-60 bg-light-orange shadow-light-orange rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-fighter-jet" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tipo de viaje</h4>
                <p className="font-size-5 mb-0">
                  Los viajes pueden ser en grupo o individuales.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1500}
              data-aos-once="true"
            >
              <div className="square-60 bg-purple-heart shadow-purple-heart rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-undo-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Cancelaciones</h4>
                <p className="font-size-5 mb-0">
                  Cancela con un click y notifica a la agencia de viajes
                  automáticamente.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1700}
              data-aos-once="true"
            >
              <div className="square-60 bg-bali-gray shadow-bali-gray rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-file-invoice" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Hojas de gastos</h4>
                <p className="font-size-5 mb-0">
                  Cada viajero/a tiene una hoja de gastos relacionada con el
                  viaje para no perder el control.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-neon-green shadow-neon-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-receipt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tickets</h4>
                <p className="font-size-5 mb-0">
                  Con la aplicación móvil los tickets estan certificados por la
                  Agencia Tributaria.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-lemon-yellow shadow-lemon-yellow rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-wallet" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Gastos</h4>
                <p className="font-size-5 mb-0">
                  Decide que gastos aprobar y liquida los viajes sin dolores de
                  cabeza.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1700}
              data-aos-once="true"
            >
              <div className="square-60 bg-scooter shadow-scooter rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-comment" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Chat</h4>
                <p className="font-size-5 mb-0">
                  Comunícate con las personas con el chat del viaje.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-light-green shadow-light-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-folder" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Documentos</h4>
                <p className="font-size-5 mb-0">
                  Adjunta todos los documentos relacionados al viaje como
                  información o datos de reserva.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-sunset shadow-sunset rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-heart" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Control</h4>
                <p className="font-size-5 mb-0">
                  ¡Ahorra hasta un 80% de tu tiempo y evita sorpresas al
                  facturar!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content9;

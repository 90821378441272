import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Content5 from "../../sections/trips/Content5";
import Content9 from "../../sections/trips/Content9";
import CTA from "../../components/CTA/CTA";
import Content2 from "../../sections/trips/Content2";
import Content1 from "../../sections/trips/Content1";

const Trips = () => {
  return (
    <>
      <PageWrapper>
        <Content5 className="pt-26 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="bg-default-6 pt-11 pt-lg-34 pb-0 pb-lg-31" />
        <Content9 className="pt-11 pt-lg-28 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <Content1 className="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <CTA className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default Trips;
